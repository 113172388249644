
import Backdrop from '@/components/Backdrop.vue'
import { appStore, currentStore, paneStore } from '@/store'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Breadcrumb',
  data() {
    return {
      limitPath: false,
      states: {
        subNavIsVisible: false,
      },
      debounce: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        timeout: null as any,
        delay: 50,
      },
    }
  },
  computed: {
    ...mapState(appStore, ['isResale']),
    isPDF() {
      return [
        'FullReport',
        'ScreeningReport',
        'DamageRelatedScreeningReport',
        'SamplesDocument',
      ].includes(this.$route.name as string)
    },
    reportScope() {
      const reference = this.$route.query.reference as string
      const id = reference?.split('-')[1] ?? ''

      if (!id) {
        return this.mixWB('ALL_BUILDINGS')
      }

      const building = currentStore().units.active.buildings.find(
        (x) => x.id === id
      )

      if (!building) {
        return this.mixWB('UNKNOWN')
      }

      return building.getFullTitle()
    },
    path(): { url: string; translation: string }[] {
      const home = {
        url: '/',
        translation: '',
      }

      const { name, path, meta, params } = this.$route

      if (!name) {
        return [home]
      }

      const page = {
        url: `${path.split(meta.url as string)[0]}${meta.url}`,
        translation: this.mixWB(meta.translation as string),
      }

      // When paneIDs is a string it means no panes are active.
      if (!params.paneIDs || typeof params.paneIDs === 'string') {
        return [home, page]
      }

      const panes =
        params.paneIDs.map((id) => ({
          url: `${path.split(id)[0]}${id}`,
          translation: this.getPaneTitleByID(id),
        })) ?? []

      return [home, page, ...panes]
    },
    first(): { url: string; translation: string } {
      return this.path[0]
    },
    subpath(): { url: string; translation: string }[] {
      return this.path.length > 1 ? this.path.slice(1, -1) : []
    },
    last(): { url: string; translation: string } | null {
      return this.path.length > 1 ? this.path.slice(-1)[0] : null
    },
  },
  watch: {
    path() {
      // Rerun calc on path change
      this.debounceFn()
      this.states.subNavIsVisible = false
    },
  },
  methods: {
    ...mapActions(paneStore, ['getPaneTitleByID']),
    toggleSubNav() {
      this.states.subNavIsVisible = !this.states.subNavIsVisible
    },
    setPathLimit() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const shadowNav = (this.$refs as any).shadowNav as HTMLElement | null
      const container = this.$el as HTMLElement | null

      if (!shadowNav || !container) {
        return
      }

      const breadcrumbWidth = Math.floor(
        container.getBoundingClientRect().width
      )
      const shadowNavWidth = Math.floor(shadowNav.getBoundingClientRect().width)

      this.limitPath = shadowNavWidth > breadcrumbWidth
    },
    toURLSegment(segment: string) {
      return this.$routeAppend(segment, this.$route.fullPath.split(segment)[0])
    },
    debounceFn() {
      if (this.debounce.timeout) {
        clearTimeout(this.debounce.timeout)
      }

      this.debounce.timeout = setTimeout(this.setPathLimit, this.debounce.delay)
    },
  },
  components: { Backdrop },
  created() {
    window.addEventListener('resize', this.debounceFn)
  },
  mounted() {
    this.$nextTick(this.setPathLimit)
  },
  beforeUnmount() {
    window.addEventListener('resize', this.debounceFn)
  },
})
